import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "normalize.css/normalize.css";
import ElementUI from "element-ui";
import "../public/abc.css";
// import '../src/assets/resset.css'
import "element-ui/lib/theme-chalk/index.css";
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
router.afterEach(() => {
  window.scrollTo(0, 0);
});
Vue.use(ElementUI);

import "@/utils/rem";
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
