<template>
  <div id="app">
    <div class="Top-menu">
      <div class="logo-image" @click="iconLogo">
        <img src="../images/02师资专家/logo-red.png" alt="" />
      </div>
      <el-menu
        mode="horizontal"
        :default-active="activeIndex"
        class="el-menu-demo"
        background-color="#fff"
        text-color="#313131"
        active-text-color="#313131"
        @select="handleSelect"
        router
      >
        <el-menu-item index="/home">网站首页</el-menu-item>
        <el-menu-item index="/expertPage">师资专家</el-menu-item>
        <el-menu-item :index="'/schoolActivity'">校园动态</el-menu-item>
        <el-menu-item index="/contactPage">联系我们</el-menu-item>
        <el-menu-item index="/abountPage">关于我们</el-menu-item>

        <!-- <el-menu-item index="/studentProfile">档案查询</el-menu-item>-->
      </el-menu>
    </div>
    <router-view />
    <div class="footer">
      <div class="footer-top">
        <div class="footer-box1">
          <img src="../images/06底部/icon60-contact.png" alt="" />
          <p>联系我们</p>
          <p>400-031-5329</p>
        </div>
        <div class="footer-box2 footer-box1">
          <img src="../images/06底部/icon60-address.png" alt="" />
          <p>学校地址</p>
          <p>石家庄正园博园大街2号</p>
        </div>
        <div class="footer-box1">
          <img src="../images/06底部/icon60-net.png" alt="" />
          <p>网站名称</p>
          <p>石家庄御珍堂培训学校_御珍堂网校<br />【官网】</p>
        </div>
      </div>
      <div class="footer-content">
        <p @click="websiteHome">网站首页</p>
        <p @click="aboutUs">关于我们</p>
        <p @click="contactUs">联系我们</p>
      </div>
      <div class="footer-bottom">
        <div class="footer-bottom-content">
          <p>
            <span>御珍堂职业培训学校官网</span><span>冀ICP备15003582号-3</span>
          </p>
          <p>售后投诉电话：0311-66859739、17367914043 (工作时间8:30-18:00)</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: this.$route.path,
    };
  },
  watch: {
    $route(to) {
      // console.log(to.path);
      // 监听路由的变化  如果路由发生改变则当前tab栏默认值也相应改变
      this.activeIndex = to.path;
      // 手动更改 为了保持选中状态
      if (to.path == "/activityDetail") {
        this.activeIndex = "/schoolActivity";
      }
    },
  },
  methods: {
    iconLogo() {
      // console.log(123);
      this.$router.push("/home");
    },
    handleSelect(key) {
      this.activeIndex = key;
    },
    mounted() {
      console.log(this.$router);
    },
    websiteHome() {
      this.$router.push("/");
      document.documentElement.scrollTop = 0;
    },
    aboutUs() {
      this.$router.push("/abountPage");
    },
    contactUs() {
      this.$router.push("/contactPage");
    },
  },
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 1.875rem;
  background: pink;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
// /deep/.el-menu-item:hover {
//   background-color: rgba(255, 255, 255,) !important;
// }
.el-menu--horizontal > .el-menu-item {
  font-size: 0.875rem;
  width: 6rem;
  height: 3.75rem !important;
  line-height: 3.75rem !important;
  display: flex;
  justify-content: center;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 0.125rem solid rgb(49, 49, 49) !important;
}
.el-menu-item:not(.is-disabled):hover,
.el-menu-item:not(.is-disabled):focus {
  background-color: #fff !important;
}
.Top-menu {
  margin: 0 auto;
  width: 70%;
  display: flex;
  align-items: center;
  // box-sizing: border-box;
  justify-content: space-around;
  height: 5rem;
  background-color: #fff;
}
.logo-image {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 12.125rem;
    height: 3rem;
  }
}
.footer-bottom {
  background-color: #131313;
  height: 3.5rem;
  width: 100%;

  .footer-bottom-content {
    width: 55%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    p {
      margin: 0 0;
      height: 3.5rem;
      line-height: 3.5rem;
      word-break: normal;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow: hidden;
    }
    p:nth-of-type(1) {
      color: #666666;
      span:nth-of-type(1) {
        margin-right: 2.625rem;
      }
    }
    p:nth-of-type(2) {
      color: #ffffff;
    }
  }
}
.footer-top {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;

  .footer-box2 {
    border-left: 0.125rem solid #303030;
    border-right: 0.125rem solid #303030;
  }

  .footer-box1 {
    width: 25rem;
    height: 9.625rem;

    img {
      width: 3.75rem;
      height: 3.75rem;
      margin-bottom: 0.1875rem;
    }

    p:nth-of-type(1) {
      font-size: 1.25rem;
      font-weight: 600;
      color: #fff;
      margin: 0 0;
      line-height: 1.875rem;
      margin-bottom: 0.25rem;
    }

    p:nth-of-type(2) {
      margin: 0 0;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.375rem;
      font-size: 0.75rem;
    }
  }
}
.footer {
  width: 100%;
  background-color: #1a1a1a;
  padding-top: 5rem;
  box-sizing: border-box;

  .footer-content {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;

    p {
      width: 8.125rem;
      color: #666666;
      margin: 0 0;
      font-weight: 400;
      font-size: 1.125rem;
      cursor: pointer;
    }

    p:nth-of-type(2) {
      border-left: 0.0625rem solid #303030;
      border-right: 0.0625rem solid #303030;
    }
  }
}
</style>
