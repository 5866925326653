const baseSize = 16;
// 设置根节点的font-size大小函数
function setRem() {
  const scale = document.documentElement.clientWidth / 1920;
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = baseSize * scale + "px";
  document.documentElement.style.height = 1080 * scale + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置根节点的font-size大小
window.onresize = function () {
  setRem();
};
